$primary-font: "Raleway", sans-serif;
$secondary-font: "Open Sans", sans-serif;

#about {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  .nav-section {
    position: relative;
    width: 40%;
    height: 100%;
    z-index: 9;
  }
  .main-section {
    position: relative;
    margin: 0 auto;
    height: 100vh;
    width: 60%;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .first-column {
      display: grid;
      grid-template-rows: 50vh 50vh;
      width: 100%;
      height: 100%;
      .image-box {
        height: 100%;
        width: 100%;
        border-bottom: 5px solid #161629;
        border-right: 5px solid #161629;
        .about-pic {
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
             object-fit: contain;
          -o-object-position: center;
             object-position: center;
          background: transparent;
        }
      }
      .text-box {
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        border-top: 5px solid #161629;
        border-right: 5px solid #161629;
        .text {
          width: 80%;
          margin: 0 auto;
          text-align: left;
          h2 {
            font-size: 1.2rem;
            font-weight: 800;
            font-family: $primary-font;
            text-transform: uppercase;
            color: rgb(114, 114, 114);
          }
          p {
            font-size: 1rem;
            font-weight: 600;
            font-family: $secondary-font;
            
            margin: 35px 0;
            color: rgb(160, 160, 160);
            letter-spacing: 1.2px;
          }
        }
      }
    }
    .second-column {
      display: grid;
      grid-template-rows: 50vh 50vh;
      width: 100%;
      height: 100%;
      .text-box {
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        border-bottom: 5px solid #161629;
        border-left: 5px solid #161629;
        .text {
          width: 80%;
          margin: 0 auto;
          text-align: left;
          h2 {
            font-size: 1.2rem;
            font-weight: 800;
            font-family: $primary-font;
            text-transform: uppercase;
            color: rgb(114, 114, 114);
          }
          p {
            font-size: 1rem;
            font-weight: 600;
            font-family: $secondary-font;
            
            margin: 35px 0;
            color: rgb(160, 160, 160);
            letter-spacing: 1.2px;
          }
        }
      }
      .image-box {
        border-top: 5px solid #161629;
        border-left: 5px solid #161629;
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        .skills-pic {
          width: 90%;
          height: 90%;
          margin: 0 auto;
          -o-object-fit: contain;
             object-fit: contain;
          -o-object-position: center;
             object-position: center;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  #about {
    .main-section {
      height: 100%;
      overflow: auto;
      overflow-x: hidden;
      .first-column {
        grid-template-rows: 1fr 1fr;
      }
      .second-column {
        grid-template-rows: 1fr 1fr;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #about {
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow: auto;
    .nav-section {
      width: 100%;
      height: 100%;
    }
    .main-section {
      grid-template-columns: 1fr;
      width: 100%;

      .first-column {
        grid-template-rows: 1fr;
        width: 100%;
        .image-box {
          border: none;
          height: 100%;
          width: 100%;
          padding-top: 50px;
          .about-pic {
            width: 100%;
            height: 80%;
            margin: 0 auto;
            -o-object-fit: contain;
               object-fit: contain;
            -o-object-position: center;
               object-position: center;
          }
        }
        .text-box {
          border: none;
          border-top: 5px solid #161629;
          border-bottom: 5px solid #161629;
          .text {
            margin: 40px 0;
          }
        }
      }
      .second-column {
        grid-template-rows: 1fr;
        .text-box {
          border: none;
          border-top: none;
          border-bottom: 5px solid #161629;
          .text {
            margin: 40px 0;
          }
        }
        .image-box {
          border: none;
          border: none;
        }
      }
    }
  }
}
